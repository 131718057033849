<template>
  <div>
    <div class="custom-popup-backdrop"></div>
    <div class="custom-popup pb-md-8 pt-md-6 fade-in-bottom dots-bg explanation-popup">
      <div class="i2-inner-circle animate circle-animate-popup"></div>
      <div v-if="title" class="content pb-6 mb-6" :class="{ 'animate' : animate }" >
        <div class="d-flex flex-column">
          <div class="d-block">
            <div class="h1"><span>{{ title }}</span></div>
            <div class="h1"><span>{{ subtitle }}</span></div>
            <!-- <div class="h1"><span>{{ thirdTitle }}</span></div> -->
          </div>
        </div>
      </div>
      <div class="position-relative z-i-505 explanation-popup-close-button">
        <button v-if="!page" @click="close()" class="close btn btn-link btn-sm"><img src="@/assets/images/cancel.svg" alt="">
        </button>
      </div>
      <div class="inner justify-content-between overflow-x-hidden flex-column" :class="{ 'strictly-overflow' : id === 2 }" style="min-width:0;flex:1;min-height: 0;">
        <template v-if="id === 1">
         <div class="w-100 router-images position-relative">
           <div>
             <div class="swiper-button-prev" @click="slideRight"></div>
             <div class="swiper-button-next" @click="slideLeft"></div>
           </div>
           <div class="telekom-slider" :class="{ [slider.lastSwipe] : true, 'animate-to-left': slider.animateToLeft, 'animate-to-right': slider.animateToRight }">
             <div class="slide" :data-index="index"
             :class="{ 'left': index === 6, 'center': index === 7, 'right': index === 8 }" v-for="(item, index) in ['bis 60', 'bis 100', 'ab 100', 'bis 60', 'bis 100', 'ab 100', 'bis 60', 'bis 100', 'ab 100', 'bis 60', 'bis 100', 'ab 100', 'bis 60', 'bis 100', 'ab 100']"
             :key="'slide' + index">
               <div class="slide-inner">
                 <span class="sliding-button">{{ item }}m<sup>2</sup></span>
                 <img :src="require('@/assets/images/flats/' + item + '.png')" alt="">
               </div>
             </div>
           </div>
           <div id="lottie-wrapper" :class="{ 'active': showLottie }">
             <div id="lottie1" class="lottie" :class="{ 'active' : !showOtherRouters }"></div>
             <div id="lottie2" class="lottie" :class="{ 'active' : showOtherRouters && routersActive.includes(2) }"></div>
             <div id="lottie3" class="lottie" :class="{ 'active' : currentFivegRouter }"></div>
           </div>
         </div>
          <div class="d-block py-5 w-100">
            <div class="routers justify-content-center align-items-center d-flex">
              <div class="d-flex">
                <div @click="hideAll" class="blue router-circle mx-4"
                     :class="{ 'active' : !showOtherRouters, 'default' : showOtherRouters }">
                  <span class="blue"></span>
                  <img :src="require('@/assets/images/routers/' + 1 + '.png')" alt="">
                </div>
                <div @click="toggleRouterActive(2)" class="primary router-circle mx-4"
                     :class="{ 'active' : showOtherRouters }">
                  <span class="primary"></span>
                  <img :src="require('@/assets/images/routers/' + 2 + '.png')" alt="">
                </div>
              </div>
              <div class="d-flex five-5g-routers" :class="{ 'spacing-none' : !showOtherRouters, 'after-animation' : showOtherRouters }">
                <div v-for="index in [3, 4, 5]" :key="index" @click="toggleRouterActiveFiveG(index)" class="router-circle mx-4 primary"
                     :class="{ 'active' : currentFivegRouter === index }">
                  <span class="primary"></span>
                  <img :src="require('@/assets/images/routers/' + index + '.png')" alt="">
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="id === 2">
          <div class="d-flex justify-content-between align-items-center explanation-2-wrapper">
            <div class="i2-inner-circle animate circle-animate-popup"></div>
            <div v-if="title" class="content pb-6 mb-6" :class="{ 'animate' : animate }" >
              <div class="d-flex flex-column">
                <div class="d-block">
                  <div class="h1"><span>{{ title }}</span></div>
                  <div class="h1"><span>{{ subtitle }}</span></div>
                  <div class="h1"><span>{{ thirdTitle }}</span></div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column align-items-start explanation-2-buttons">
              <button class="btn btn-default btn-f" :class="{ 'active': currentExplanationSlide === 1 }" @click="fadeInCircle($event, 1)">Stahlbeton<span></span></button>
              <button class="btn btn-default btn-s" :class="{ 'active': currentExplanationSlide === 2 }" @click="fadeInCircle($event, 2)">Fußbodenheizung<span></span></button>
              <button class="btn btn-default btn-th" :class="{ 'active': currentExplanationSlide === 3 }" @click="fadeInCircle($event, 3)">Elektrogeräte<span></span></button>
            </div>
            <img :src="require('@/assets/images/home_v1.png')" alt="">
            <div style="left: 50%; top: 50%" id="fade-in-circle" :class="{ 'active': showRouterInfoPanel, 'initial': showRouterInfoPanelInitial }"></div>
            <button :class="{ 'active': showRouterInfoPanel }" class="btn btn-default close-router-info" @click="hideInfoPanel">
              <img :src="require('@/assets/images/cancel.svg')" alt="">
            </button>
            <div class="explanation-2-slides">
              <template v-for="i in 3">
                <div :key="i">
                  <img class="router-img" :class="{ 'active': currentExplanationSlide === i, 'r-3': i === 3 }" :src="require('@/assets/images/router-new.png')" alt="">
                  <img class="wifi" :class="{ 'active': currentExplanationSlide === i }" :src="require('@/assets/images/routers/slides/' + i + '.png')" alt="">
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import lottie from 'lottie-web'

export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    thirdTitle: {
      type: String,
      default: ''
    },
    page: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      video: false,
      routersActive: [],
      animate: false,
      showOtherRouters: false,
      showRouterInfoPanel: false,
      currentExplanationSlide: null,
      showLottie: true,
      showRouterInfoPanelInitial: false,
      isAnimating: false,
      currentFivegRouter: null,
      flats: [
        'ab 60m',
        'bis 100m2',
        'bis 60m2'
      ],
      slider: {
        animateToLeft: false,
        animateToRight: false,
        isAnimating: false,
        lastSwipe: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('togglePanel', 'explanation', true)
    },
    openVideo (url) {
      this.video = (url === undefined) ? '://0' : '/videos/' + url
    },
    slideRight () {
      if (this.slider.isAnimating) return
      this.slider.lastSwipe = 'to-the-right'
      this.slider.isAnimating = true

      let center = document.querySelector('.center')
      let index = parseInt(center.dataset.index)
      let left = document.querySelector('.slide[data-index="' + (index - 1) + '"]')
      this.slider.animateToRight = true

      setTimeout(() => {
        let x = document.querySelector('.slide[data-index="' + (index + 1) + '"]')
        if (x) x.classList.remove('right')

        let z = document.querySelector('.slide[data-index="' + (index - 2) + '"]')
        if (z) z.classList.add('pre-left')

        let y = document.querySelector('.slide[data-index="' + (index - 2) + '"]')
        if (!y) {
          this.slideDuplicate('-')
          document.querySelector('.slide[data-index="' + (index + 1) + '"]').classList.remove('right')
        }
      }, 1000)

      setTimeout(() => {
        center.classList.remove('center')
        center.classList.add('right')

        if (left) {
          left.classList.remove('left')
          left.classList.add('center')
        }

        let rightNext = document.querySelector('.slide[data-index="' + (index - 2) + '"]')
        if (rightNext) {
          rightNext.classList.add('left')
          rightNext.classList.remove('pre-left')
        }

        this.slider.animateToRight = this.slider.isAnimating = false
      }, 1800)
    },
    slideLeft () {
      if (this.slider.isAnimating) return
      this.slider.lastSwipe = 'to-the-left'
      this.slider.isAnimating = true

      let center = document.querySelector('.center')
      let index = parseInt(center.dataset.index)
      let right = document.querySelector('.slide[data-index="' + (index + 1) + '"]')
      this.slider.animateToLeft = true

      setTimeout(() => {
        let x = document.querySelector('.slide[data-index="' + (index - 1) + '"]')
        if (x) x.classList.remove('left')

        let y = document.querySelector('.slide[data-index="' + (index + 2) + '"]')
        if (!y) {
          this.slideDuplicate('+')
          document.querySelector('.slide[data-index="' + (index - 1) + '"]').classList.remove('left')
        }
      }, 1000)

      setTimeout(() => {
        center.classList.remove('center')
        center.classList.add('left')

        right.classList.remove('right')
        right.classList.add('center')

        let rightNext = document.querySelector('.slide[data-index="' + (index + 2) + '"]')
        if (rightNext) rightNext.classList.add('right')

        this.slider.animateToLeft = this.slider.isAnimating = false
      }, 1800)
    },
    slideDuplicate (sign) {
      let slider = document.querySelector('.telekom-slider')
      let slides = document.querySelectorAll('.telekom-slider .slide')
      let index = 1
      if (sign === '+') index = parseInt(slides[slides.length - 1].dataset.index) + 1
      else index = parseInt(slides[0].dataset.index) - 1

      if (sign === '+') {
        for (let i = slides.length; i > 0; i--) {
          let content = slides[i - 1]
          if (content) {
            content.cloneNode(true)
            let clone = document.createElement('div')
            clone.innerHTML = content.innerHTML
            clone.setAttribute('class', 'slide')
            clone.setAttribute('data-index', index)
            slider.append(clone)
            index++
          }
        }
      } else {
        for (let i = slides.length; i > 0; i--) {
          let content = slides[i - 1]
          if (content) {
            content.cloneNode(true)
            let clone = document.createElement('div')
            clone.innerHTML = content.innerHTML
            clone.setAttribute('class', 'slide')
            clone.setAttribute('data-index', index)
            slider.prepend(clone)
            index--
          }
        }
      }
    },
    hideAll () {
      this.routersActive = []
      this.showOtherRouters = false
      this.currentFivegRouter = null
    },
    hideInfoPanel () {
      this.showRouterInfoPanel = this.currentExplanationSlide = null
      setTimeout(() => {
        this.showRouterInfoPanelInitial = true
      }, 1100)
    },
    toggleRouterActive (id) {
      if (this.routersActive.includes(id)) {
        if (id === 2 || id === 1) {
          this.showOtherRouters = false
          this.currentFivegRouter = null
          if (id === 1) {
            this.routersActive = []
          }
        }
        this.routersActive.splice(this.routersActive.indexOf(id), 1)
      } else {
        if (id === 2) this.showOtherRouters = true
        if (id === 1) {
          this.routersActive = []
          this.currentFivegRouter = null
        }
        this.routersActive.push(id)
      }
    },
    toggleRouterActiveFiveG (id) {
      if (this.currentFivegRouter !== null) {
        this.currentFivegRouter = null
        setTimeout(() => {
          this.currentFivegRouter = id
        }, 400)
      } else this.currentFivegRouter = id
    },
    routerAnimation (id, color = 'purple') {
      lottie.loadAnimation({
        container: document.getElementById('lottie' + id),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: (color === 'purple') ? require('@/assets/json-animations/purple-animation.json') : require('@/assets/json-animations/blue-animation.json')
      })
    },
    fadeInCircle (e, id) {
      if (this.isAnimating) return

      this.isAnimating = true
      if (!this.showRouterInfoPanel) {
        setTimeout(() => {
          this.showRouterInfoPanelInitial = true
          this.setCirclePosition(e)

          setTimeout(() => {
            this.showRouterInfoPanelInitial = false
            this.showRouterInfoPanel = true
            setTimeout(() => {
              this.currentExplanationSlide = id
              this.isAnimating = false
            }, 800)
          }, 100)
        }, 100)
      } else {
        this.currentExplanationSlide = id
        this.setCirclePosition(e)
        setTimeout(() => {
          this.isAnimating = false
        }, 800)
      }

      return false
    },
    setCirclePosition (e) {
      let coords = e.target.getBoundingClientRect()
      if (e.target.children[0] !== undefined) coords = e.target.children[0].getBoundingClientRect()
      document.getElementById('fade-in-circle').style.left = coords.left - 0 + 'px'
      document.getElementById('fade-in-circle').style.top = coords.top - 0 + 'px'
    }
  },
  mounted () {
    if (document.getElementById('lottie1')) {
      for (let i = 1; i < 4; i++) {
        this.routerAnimation(i, (i === 1) ? 'blue' : 'purple')
      }
    }
  }
}
</script>
